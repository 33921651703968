import React, { FC } from 'react';
// @ts-ignore
import balletShop from '../images/logos/ballet_shop.jpg';
// @ts-ignore
import danseSuisse from '../images/logos/dansesuisse.jpg';
// @ts-ignore
import jugendSport from '../images/logos/js.jpg';
// @ts-ignore
import tanzInWinterthur from '../images/logos/tanz_in_winterthur.jpg';
// @ts-ignore
import tanzVereinigung from '../images/logos/tanzvereinigung.jpg';

const NewsLinks: FC = () => {
  return (
    <div className="md:mt-8" style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridGap: '2rem' }}>
      <a href="https://ballett-shop.ch/">
        <img src={balletShop} alt="Link zu Ballet Shop Zürich"></img>
      </a>
      <a href="http://www.dansesuisse.ch/">
        <img src={danseSuisse} alt="Link zu Danse Suisse"></img>
      </a>
      <a href="https://www.jugendundsport.ch/">
        <img src={jugendSport} alt="Link zu Jugend und Sport"></img>
      </a>
      <a href="https://tanzinwinterthur.ch/">
        <img src={tanzInWinterthur} alt="Link zu Tanz in Winterthur"></img>
      </a>
      <a href="https://www.tanzvereinigung-schweiz.ch/">
        <img src={tanzVereinigung} alt="Link zu Tanz Vereinigung Schweiz"></img>
      </a>
    </div>
  );
};

export default NewsLinks;
