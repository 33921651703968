import React, { FC } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import { Routes } from '../constants/Routes';
import Title from '../components/title';
import { IFluidObject } from 'gatsby-background-image';
import Lightbox from '../components/Lightbox';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import NewsLinks from '../components/NewsLinks';
import useWindowSize from '../hooks/useWindowSize';
import useIsDesktop from '../hooks/useIsDesktop';

const DESKTOP_FACEBOOK_URL =
  'https://www.facebook.com/plugins/page.php?href=https://www.facebook.com/aha-Studio-f%C3%BCr-Tanz-und-Bewegung-106870329396711&tabs=timeline&width=340&height=500&small_header=true&adapt_container_width=true&hide_cover=true&show_facepile=true&appId=1868515433393770';

const MOBILE_FACEBOOK_URL =
  'https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fpg%2Faha-Studio-f%25C3%25BCr-Tanz-und-Bewegung-106870329396711%2Fabout%2F&tabs&width=340&height=154&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=1868515433393770';
const NEWS_QUERY = graphql`
  {
    allContentfulNews(limit: 8) {
      nodes {
        titel
        date
        bilder {
          fluid {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        body {
          json
        }
      }
    }
  }
`;

type News = {
  id: string;
  titel: string;
  date: Date;
  json: any;
  bilder: IFluidObject[];
};

const NewsPage: FC = () => {
  const data = useStaticQuery(NEWS_QUERY);
  const newsRaw: News[] = data.allContentfulNews && data.allContentfulNews.nodes.map((node: any) => ({
    id: node.id,
    titel: node.titel || null,
    date: new Date(node.date),
    json: (node.body && node.body.json) || null,
    bilder: node.bilder ? node.bilder.map((b: any) => b.fluid) : [],
  }));
  // makes sure there is alwayas a valid entry in the news section
  const news = newsRaw.filter(news => news.titel !== 'DUMMY_NEWS');
  const isDesktop = useIsDesktop();

  // @ts-ignore
  news.sort((a, b) => b.date - a.date);
  return (
    <Layout location={Routes.NEWS}>
      <SEO title="News" />
      <div className="mt-8">
        <Title className="mb-4 text-center">News</Title>
        <div className="flex flex-col items-center lg:flex-row lg:items-start lg:w-full mx-auto lg:-mx-8">
          <div className="lg:w-4/6 lg:mx-8 lg:ml-32">
            {news && news.map(n => (
              <div
                key={n.id}
                className="transition-all transition-250 hover:shadow-xl max-w-4xl rounded overflow-hidden shadow-lg mx-auto mb-12"
              >
                <div className="">
                  {n.bilder && n.bilder.length > 0 && (
                    <Lightbox
                      className="w-full max-w-md"
                      galleryButtonVisible={false}
                      autoPlaying={false}
                      youtubeUrls={[]}
                      images={n.bilder}
                    />
                  )}
                  <div className="w-full p-4">
                    <div className="px-6 py-4">
                      {n.titel && <div className="font-bold text-xl mb-2">{n.titel}</div>}
                      {n.json && <div className="markdown-body">{documentToReactComponents(n.json)}</div>}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="lg:w-2/6 block lg:mx-8">
            <iframe
              src={isDesktop ? DESKTOP_FACEBOOK_URL : MOBILE_FACEBOOK_URL}
              width="340"
              height={isDesktop ? "500" : "240"}
              style={{ border: 'none', overflow: 'hidden' }}
              scrolling="no"
              allow="encrypted-media"
            ></iframe>

            <NewsLinks />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default NewsPage;
